'use client';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import Onboarding from '../../components/Onboarding/Onboarding';

export default function HomePage() {
  // BEGIN TODO: remove all of the below once the onboardV2 feature is approved for prod
  const LDLClient = useLDClient();
  const LDLContext = LDLClient?.getContext();

  useEffect(() => {
    console.log('Launch Darkly Context: ', LDLContext);
    console.log('App dir deploy worked!');
  }, [LDLContext]);
  // END TODO: remove all of the above once the onboardV2 feature is approved for prod

  return <Onboarding />;
}
